<script>
import MarkText from 'mark.js'

export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    search: {
      type: [Array, String],
      default: () => ''
    }
  },
  mounted () {
    this.mark = new MarkText(this.$refs.span)
    this.mark.mark(this.search)
  }
}
</script>

<template>
  <span class="MarkText" ref="span">{{ text }}</span>
</template>

<style lang="scss">
  .MarkText mark {
    background: transparent;
    font-weight: bold;
    color: inherit;
  }
</style>
