<script>
import MarkText from 'components/MarkText.vue'
import { onUnmounted } from 'src/lib/vutil.js'
import { ref } from 'vue'
import { useQuasar } from 'quasar'
import { useRest } from 'src/lib/rest.js'

export default {
  components: { MarkText },
  props: {
    modelValue: { type: String, default: '' },
    restUrl: { type: String, default: '' }
  },
  emits: [
    'update:modelValue',
    'enter'
  ],
  setup (props, { emit }) {
    const rest = useRest()
    const options = ref([])
    const search = ref('')
    const userSelecting = ref(false)
    const select = ref(null)
    const quasar = useQuasar()
    const keyHandler = ev => {
      if (ev.code === 'Enter' && !userSelecting.value) {
        ev.preventDefault()
        userSelecting.value = false
        emit('Enter')
      } else if (ev.code === 'ArrowUp' || ev.code === 'ArrowDown') {
        userSelecting.value = true
      } else if (userSelecting.value) {
        userSelecting.value = false
      }
    }

    onUnmounted(() => {
      onBlur()
    })

    const onFocus = () => {
      select.value?.$el?.control?.addEventListener('keydown', keyHandler)
    }
    const onBlur = () => {
      select.value?.$el?.control?.removeEventListener('keydown', keyHandler)
    }

    const filter = async (val, update, abort) => {
      try {
        search.value = val
        const q = (val || '').trim()
        if (q) {
          const opts = await rest.get(`${props.restUrl}${props.restUrl.includes('?') ? '&' : '?'}q=${encodeURIComponent(val)}`)
          update(() => {
            options.value = Object.freeze(opts || [])
          })
        } else {
          update(() => {
            options.value = Object.freeze([])
          })
        }
      } catch (err) {
        console.warn(`Could not retrieve autocomplete from "${props.restUrl}" for "${val}":`, err)
        abort()
      }
    }
    const focus = () => select.value.focus()

    const update = val => emit('update:modelValue', val)
    return {
      options,
      search,
      userSelecting,
      select,
      onFocus,
      onBlur,
      filter,
      focus,
      update,
      quasar
    }
  }

}
</script>

<template>
  <q-select
    v-bind="$attrs"
    ref="select"
    :model-value="modelValue"
    @update:modelValue="update"
    @input-value="update"
    use-input
    fill-input
    hide-selected
    @filter="filter"
    :options="options"
    new-value-mode="add"
    :options-dense="quasar.platform.is.desktop"
    input-debounce="250"
    class="AutocompleteInput"
    @focus="onFocus"
    @blur="onBlur"
    behavior="menu"
  >
    <slot v-for="(_, name) in $slots" :name="name" slot="name" />
    <template #option="scope">
      <q-no-ssr>
        <q-item v-bind="scope.itemProps">
          <q-item-section>
            <MarkText :text="scope.opt" :search="modelValue" />
          </q-item-section>
        </q-item>
      </q-no-ssr>
    </template>
  </q-select>
</template>
<style lang="scss" scoped>
.AutocompleteInput ::v-deep(.q-field__append) {
  // Hide the arrow
  display: none;
}
.AutocompleteInput ::v-deep(input[type="search"]) {
  -webkit-appearance: initial;
}
</style>
